import React from 'react';
import '../styles/Links.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faGithub, faMedium } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

const Links = () => {
  return (
    <div className="links">
      <div className='social-link'>
        <FontAwesomeIcon icon={faLinkedin} className="icon" />
        <a className='links-icon' href="https://www.linkedin.com/in/tommasogiannoni/" target="_blank" rel="noopener noreferrer">LinkedIn</a>
      </div>
      <div className='social-link'>
        <FontAwesomeIcon icon={faGithub} className="icon" />
        <a className='links-icon' href="https://github.com/tommasogiannoni" target="_blank" rel="noopener noreferrer">GitHub</a>
      </div>
      <div className='social-link'>
        <FontAwesomeIcon icon={faMedium} className="icon" />
        <a className='links-icon' href="https://medium.com/@tommaso.giannoni" target="_blank" rel="noopener noreferrer">Medium</a>
      </div>
      <div className='social-link'>
        <FontAwesomeIcon icon={faEnvelope} className="icon" />
        <a className='links-icon' href="mailto:tommaso.giannoni@yahoo.it">Email Me</a>
      </div>
    </div>
  );
}
export default Links;