
import React, { useEffect } from 'react';
import './App.css';
import Profile from './components/Profile';
import Links from './components/Links';
import ThemeSwitch from './components/ThemeSwitch';
import {  useTheme } from './ThemeContext';
import 'animate.css';

function App() {
  const { theme } = useTheme();

  useEffect(() => {
    document.body.className = theme + '-theme';
  }, [theme]);

  return (
    <div className="App">
      <div className='contents animate__zoomIn'>
        <Profile />
        <Links />
        <ThemeSwitch />
      </div>
    </div>
  );
}

export default App;