import React from 'react';
import profileImage from '../assets/profileimage.png'; // Add your profile image in the assets folder
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBriefcase, faLocationPin } from '@fortawesome/free-solid-svg-icons';
import '../styles/Profile.css';

const Profile = () => {
    return (
      <div className="profile">
        <div className='header-profile'>
          <div className='sign'>
            <h2>Tommaso Giannoni</h2>
            <h3><FontAwesomeIcon icon={faBriefcase}  className="icon-job"/> Software Engineer</h3>
            <p><FontAwesomeIcon icon={faLocationPin} className="icon-location" /> Italy, Europe</p>
          </div>
          <img src={profileImage} alt="Profile" className="profile-image" />
        </div>
       

      </div>
    );
  }

export default Profile;