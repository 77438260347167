import React from 'react';
import { useTheme } from '../ThemeContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSun, faMoon } from '@fortawesome/free-solid-svg-icons';
import '../styles/ThemeSwitch.css';

const ThemeSwitch = () => {
  const { theme, toggleTheme } = useTheme();

  return (
    <button onClick={toggleTheme} className="theme-switch">
      <FontAwesomeIcon icon={theme === 'light' ? faMoon : faSun} />
    </button> 
  );
};

export default ThemeSwitch;